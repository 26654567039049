'use client'

import { currentTimeAtom } from '@/atoms'
import dayjs from 'dayjs'
import { useSetAtom } from 'jotai'
import useInterval from 'use-interval'

const GLOBAL_TIMER_INTERVAL = 300

export default function GlobalTimer() {
  const setCurrentTime = useSetAtom(currentTimeAtom)

  useInterval(() => {
    setCurrentTime(dayjs())
  }, GLOBAL_TIMER_INTERVAL)

  return null
}
