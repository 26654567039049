'use client'

import Link from '@/components/link'
import { cls } from '@/utils'
import IconLink from '@/components/icon-link'
import { ButtonMore } from '../button-more'
import LogoSymbol from '@/public/assets/logo-symbol.svg'
import LogoFull from '@/public/assets/logobeta-full.svg'
import IconDots from '@haiper/icons-svg/icons/outline/dot-hor.svg'
import ThemeToggle from '@/components/theme-toggle'
import { NO_OUTLINE_STYLE, sidebarWidthStyle } from '@/constants'
import useNavLinks from '@/hooks/useNavLinks'
import useFooterLinks from '@/hooks/useFooterLinks'

interface NavProps {
  className?: string
  activeKey?: string
}

const MoreOutline = IconDots

export function Nav({ className, activeKey }: NavProps) {
  const { data: navLinks } = useNavLinks()
  const { data: footerLinks } = useFooterLinks()
  return (
    <nav
      className={cls(
        'hidden md:flex flex-col shrink-0 overflow-y-auto no-scrollbar border-r-2 widescreen:border-0 border-solid border-border h-auto',
        sidebarWidthStyle,
        className, // external className
      )}
      data-testid='sidebar'
    >
      <div className='w-full flex justify-center widescreen:justify-start pt-5 pb-2'>
        <Link
          href='https://haiper.ai/home'
          target='_blank'
          rel='noopener noreferer'
          className={cls('focus-visible:ring-0 text-icon relative', NO_OUTLINE_STYLE)}
          aria-label='sidebar logo'
        >
          <LogoSymbol height={72} className='h-18 block widescreen:hidden mx-auto' />
          <LogoFull height={72} className='h-18 hidden widescreen:block' />
        </Link>
      </div>
      <div className='h-0 widescreen:hidden border border-border border-solid w-8 mx-auto mb-3'></div>
      {/* <div className='h-0.5 mx-6 border border-solid border-border mb-4' /> */}
      <div className='flex-1 flex flex-col p-2 mb-3 gap-2'>
        <div className='min-h-0 flex-1 flex flex-col gap-1' aria-label='nav'>
          {navLinks.map(
            ({ id, href, icon, activeIcon, badge, label, key, onClick, className, iconClassName, labelClassName }) => (
              <IconLink
                key={key}
                id={id}
                href={href}
                icon={key === activeKey ? activeIcon : icon}
                badge={badge}
                iconClassName={iconClassName}
                labelClassName={labelClassName}
                className={className}
                active={key === activeKey}
                title={label}
                onClick={onClick}
              >
                {label}
              </IconLink>
            ),
          )}
        </div>
        <div className='flex flex-col gap-1' aria-label='footer'>
          {footerLinks.map(({ href, icon, activeIcon, label, key, target }) => (
            <IconLink
              key={key}
              href={href}
              icon={key === activeKey ? activeIcon : icon}
              target={target}
              title={label}
              active={key === activeKey}
            >
              {label}
            </IconLink>
          ))}
          <ButtonMore>
            <IconLink icon={MoreOutline} className={cls('flex appearance-none')} href='#'>
              More
            </IconLink>
          </ButtonMore>
          <div className='flex justify-center widescreen:justify-start widescreen:px-3 my-5'>
            <ThemeToggle />
          </div>
        </div>
      </div>
    </nav>
  )
}
